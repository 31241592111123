<template>
     <!-- Main content -->
    <section class="content-wrapper">
        <section class="content-header">
            <h1>Supported Weights</h1>
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#"><i class="iconsmind-Library"></i></a></li>
                <li class="breadcrumb-item"><router-link :to="{path: '/'}">Home</router-link></li>
                <li class="breadcrumb-item active">Supported Weights</li>
            </ol>
        </section>
		<div class="row">
            <div class="col-12">
                <div class="row">
                    
                </div>
                <div class="row">
                    <div class="col-2 col-sm-2 col-md-2 col-lg-2 mt-30"></div>


                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 mt-30">
                        <div class="box">
                            <div class="box-header with-border">
                                <div class="">
                                    <div class="media">
                                        <div class="media-body">
                                            <h5 class="box-title">Weights</h5>
                                        </div>
                                        <div class="media-right">
                                            <button class="btn btn-block btn-success btn-sm"  data-toggle="modal" data-target="#add-supported-weight">Add</button>
                                            <NewWeight/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="box-body p-0">
                                <div class="media-list media-list-hover media-list-divided inner-user-div">
                                    <div v-for="weight in weightList" :key="weight.id">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <a class="" href="#">
                                                    <span class="title">{{weight.weight}}</span>
                                                </a>
                                            </div>
                                            <div class="col-md-6">
                                                <button v-if="isRoleAllowedDelete" @click="deleteWeight(weight.id)" class="btn btn-sm btn-danger-outline" data-toggle="tooltip" data-original-title="Delete"><i class="ti-trash" aria-hidden="true"></i></button>
                                            </div>
                                        </div><br>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div class="col-4 col-sm-4 col-md-4 col-lg-4 mt-30"></div>
                   
                    
                    
                </div>
            </div>
		</div>
    </section>
</template>

<script>
import NewWeight from '../../components/Region/NewWeight'
import { mapGetters } from 'vuex';
import { repository } from '../../presenter'
export default {
    components:{
        NewWeight,
    },
    computed: {
        ...mapGetters('region',{
            weightList: 'retrieveWeights',
        }),
        isRoleAllowedDelete(){
            return repository.adminIsLord()
        },
    },
    data() {
        return{
            publicPath: process.env.BASE_URL,
            deleteLoading: false,
        }
    },
    mounted() {
        const listJS = document.createElement('script')
        listJS.setAttribute('src', `${this.publicPath}assets/js/pages/list.js`)
        document.head.appendChild(listJS)

        this.loadWeightList();
    },
    methods: {
        loadWeightList(){
            repository.regions.loadWeightList();
        },
        deleteWeight(weightID) {
            if (confirm('Are you sure you want to delete this weight? This weight will be removed from price lists and this action cannot be reversed, do you want to proceed?')){
                this.deleteLoading=true;
                repository.regions.deleteWeight(weightID);
            }
            
        },
    }
}
</script>

