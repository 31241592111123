<template>
    <div id="add-supported-weight" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel2" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="myModalLabel2">New Weight</h4>
                    <button ref="add_weight_close_button" type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                </div>
                <div class="modal-body">
                    <form class="form-horizontal form-element">
                        <div class="col-md-12 mb-20">
                            <p class="text-danger" v-if="error">{{errorMessage}}</p>
                        </div>
                        <div class="col-md-12 mb-20">
                            <div class="form-group">
                                <input v-model="weight" type="number" class="form-control" placeholder="Enter New Weight">
                                <p class="error" v-if="weightError">{{weightErrorMessage}}</p>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-info" @click="saveNewWeight">Save<span v-if="loading" class="spinner-border text-white"></span></button>
                    <button type="button" class="btn btn-default float-right" data-dismiss="modal">Cancel</button>
                </div>
            </div>
            <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
    </div>
</template>

<script>
import { repository } from '../../presenter'
export default {
  name: 'NewWeight',
  mounted() {
    const sweetAlert = document.createElement('script')
    sweetAlert.setAttribute('src', '/assets/styles/vendor_components/sweetalert/sweetalert.min.js')
    document.head.appendChild(sweetAlert)
  },
  data () {
    return {
      weight: '',
      weightError: false,
      weightErrorMessage: '',
      loading: false,
      success: false,
      successMessage: '',
      error: false,
      errorMessage: ''
    }
  },
  methods: {
    async saveNewWeight () {
      if (this.loading) return
      this.resetData()
      const newWeightDataValidation = this.validateNewWeightData()
      if (!newWeightDataValidation.success) return
      this.loading = true
      const data = {
        weight: this.weight,
      }
      const response = await repository.regions.createNewWeight(data)
      this.loading = false
      if (response.success) {
        this.resetData()
        this.showSuccessMessage('Weight Created');
        return
      }
      this.showErrorMessage(response.data)
    },

    validateNewWeightData () {
      const returnValue = new Object()
      if (!this.weight) {
        this.weightError = true
        this.weightErrorMessage = 'Enter a valid Weight'
        returnValue.success = false
        return returnValue
      }
      returnValue.success = true
      return returnValue
    },

    showSuccessMessage (message) {
      swal(message, "", "success")
      this.$refs.add_admin_close_button.click();
    },
    showErrorMessage (message) {
      this.error = true
      this.errorMessage = message
    },

    resetData () {
      this.weightError = false
      this.success = false
      this.successMessage = ''
      this.error = false
      this.errorMessage = ''
    }
  }
}
</script>

<style scoped>
@import url('/assets/styles/vendor_components/sweetalert/sweetalert.css');
</style>

